import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
import layout from '@/layout/index'  //引入根目录下的Hello.vue组件
Vue.use(Router)  //Vue全局使用Router
const routes = [              //配置路由，这里是个数组
    {                    //每一个链接都是一个对象
        path: '/',         //链接路径
        name: 'home',     //路由名称，
        component: layout,
        redirect: "/social-recruit",
        children: [{
            path: "redirect",
            name: "redirectt",
            meta: {
                title: ""
            },
            component: () => import('@/views/redirect.vue')
        }, {
            path: "social-recruit",
            name: "social-recruit",
            meta: {
                title: "社会招聘-清微智能"
            },
            component: () => import('@/views/recruit/social-recruit')
        }, {
            path: "recruit-detail",
            name: "recruit-detail",
            meta: {
                title: "社会招聘-清微智能"
            },
            component: () => import('@/views/recruit/detail')
        }, {
            path: "school",
            name: "school",
            meta: {
                title: "校园招聘-清微智能"
            },
            component: () => import('@/views/recruit/school')
        }, {
            path: "school-recruit",
            name: "school-recruit",
            meta: {
                title: "校园招聘-清微智能"
            },
            component: () => import('@/views/recruit/school-recruit')
        }, {
            path: "recruit-resume",
            name: "recruit-resume",
            meta: {
                title: "我的投递-清微智能"
            },
            component: () => import('@/views/recruit/resume')
        }],
    }
]
const router = new Router({
    routes,
    mode: 'history'
})


router.afterEach(to => {
    window.document.title = to.meta.title
    window.scrollTo(0, 0)
})

export default router