<template>
  <div class="layout">
    <el-container>
      <el-header class="hidden-xs-only" height="127px">
        <div class="container">
          <div class="top">
            <img
              src="http://www.tsingmicro.com/assets/img/logo@2x.png"
              alt=""
              width="184"
            />
            <div class="flex">
              <img
                src="http://www.tsingmicro.com/assets/img/logo2@2x.png"
                alt=""
                width="100"
              />

              <div class="icon-c color">
                <span>
                  <i class="el-icon-phone-outline u-font-20"></i>
                  <span>Tel</span>
                  <span class="prop">
                    <span class="triangle"></span>
                    010-61934861
                  </span>
                </span>
                <span>
                  <i class="el-icon-message u-font-20"></i>
                  <span>Mail</span>
                  <span class="prop-1">
                    <span class="triangle"></span>
                    tm@tsingmicro.com
                  </span>
                </span>
                <span>
                  <i class="el-icon-location u-font-20"></i>
                  <span>Add</span>
                  <span class="prop-2">
                    <span class="triangle"></span>
                    <span
                      style="
                        width: 100px;
                        white-space: normal;
                        word-wrap: break-all;
                      "
                      >北京市海淀区宝盛南路1号院26号楼领智中心南楼2层</span
                    >
                  </span>
                </span>
              </div>
            </div>
          </div>
          <ul class="nav">
            <div>
              <li :class="{ active: currentPath == '/' }">
                <a href="http://www.tsingmicro.com/">首页</a>
              </li>
              <li :class="{ active: currentPath == 'social-recruit' }">
                <router-link to="/social-recruit">社会招聘</router-link>
              </li>
              <li
                :class="{
                  active:
                    currentPath == 'school' || currentPath == 'school-recruit',
                }"
              >
                <router-link to="/school">校园招聘</router-link>
              </li>
            </div>

            <div class="nav-right">
              <li v-if="isLogin === false" style="width: 170px">
                <a href="javascript:void(0);" @click="onLogin">登录</a>
              </li>
              <li
                v-if="isLogin"
                :class="{ active: currentPath == 'recruit-resume' }"
                style="width: 170px"
              >
                <router-link to="/recruit-resume">我的</router-link>
                <div class="drop-down">
                  <router-link to="/recruit-resume">我的简历</router-link>
                  <a href="javascript:void(0);" @click="outLogin">退出登录</a>
                </div>
              </li>
              <!-- <div class="icon-1">
                <img class="icon" src="@/assets/images/wx.png" alt="" />
                <div class="code">
                  <img src="@/assets/images/wechat_qrcode.jpg" />
                </div>
              </div>
              <div class="divider" />
              <img class="icon" src="@/assets/images/wb.png" alt="" /> -->
            </div>
          </ul>
        </div>
      </el-header>
      <div class="main"><router-view></router-view></div>
      <el-footer class="hidden-xs-only" height="563px">
        <div class="container">
          <img class="row6-logo" src="@/assets/images/row5-logo.jpg" alt="" />
          <div class="footer-left fl">
            联系我们：
            <br />
            北京市海淀区宝盛南路1号院26号楼领智中心南楼2层
            <br />
            清微智能科技有限公司
            <br />
            TEL: 010-61934861
            <br />
          </div>
          <div class="footer-right fr">
            <dl>
              <dt><a href="http://www.tsingmicro.com/">首页</a></dt>
            </dl>
            <dl>
              <dt>
                <a href="http://www.tsingmicro.com/tx1-product.html"
                  >产品中心</a
                >
              </dt>
              <dd>
                <a href="http://www.tsingmicro.com/tx1-product.html">TX101</a>
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/tx2-product.html">TX210</a>
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/tx3-product.html">TX510</a>
              </dd>
            </dl>
            <dl>
              <dt>
                <a href="http://www.tsingmicro.com/solution.html">解决方案</a>
              </dt>
              <dd>
                <a href="http://www.tsingmicro.com/solution.html">新零售</a>
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/solution-two.html"
                  >智能安防</a
                >
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/solution-three.html"
                  >智能穿戴</a
                >
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/solution-four.html"
                  >智能家电</a
                >
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/solution-five.html"
                  >智能家居</a
                >
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/solution-six.html"
                  >智能终端</a
                >
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/solution-seven.html"
                  >智能车载</a
                >
              </dd>
            </dl>
            <dl>
              <dt>
                <a href="http://www.tsingmicro.com/tech.html">核心技术</a>
              </dt>
              <dd>
                <a href="http://www.tsingmicro.com/tech.html#tech-frow2"
                  >DCRA</a
                >
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/tech.html#tech-frow5"
                  >DWVT</a
                >
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/tech.html#tech-frow4"
                  >CGRA</a
                >
              </dd>
            </dl>
            <dl>
              <dt>
                <a href="http://www.tsingmicro.com/about.html">关于我们</a>
              </dt>
              <dd>
                <a href="http://www.tsingmicro.com/about.html">公司简介</a>
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/about2.html">合作伙伴</a>
              </dd>
              <dd>
                <a href="http://www.tsingmicro.com/about3.html">加入我们</a>
              </dd>
            </dl>
            <dl>
              <dt><a href="http://www.tsingmicro.com/news">新闻动态</a></dt>
            </dl>
          </div>
          <p class="copy">
            Copyright © 2019 TSING MICRO.com.cn All Rights Reserved 清微智能
            版权所有<a
              style="font-size: 12px"
              target="_blank"
              href="http://beian.miit.gov.cn/"
              >京ICP备19019133号</a
            >
          </p>
        </div>
      </el-footer>
    </el-container>
    <el-dialog
      title="清微智能网站"
      center
      :visible.sync="loginVisible"
      width="410px"
      @close="handleClose('loginForm')"
    >
      <el-form
        ref="loginForm"
        :model="loginForm"
        size="mini"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="手机号：" prop="tel" :error="errorTel">
          <el-input
            v-model="loginForm.tel"
            placeholder="请输入手机号"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="验证码：" prop="code">
          <div class="flex">
            <el-input
              v-model="loginForm.code"
              autocomplete="off"
              placeholder="请输入验证码"
            />
            <el-button
              class="m-l-24"
              size="mini"
              type="danger"
              @click="getCodeHandle"
              >{{ get_code_text }}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-button
        type="danger"
        style="width: 100%"
        @click="handLogin('loginForm')"
        >登录/注册
      </el-button>

      <el-checkbox class="m-t-24" v-model="checked" text-fill="#666666"
        >登录/注册即代表您阅读并同意
      </el-checkbox>
      <el-link type="primary" @click="goInest">《用户协议》 </el-link>

      <div class="login">
        <p>其他登录方式</p>
        <img src="@/assets/images/weixin.png" alt="" @click="wxlogin" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sms, login } from "@/assets/js/api";
export default {
  data() {
    return {
      currentPath: "social-recruit",
      errorTel: "",
      loginVisible: false,
      nav: [],
      get_code_text: "获取验证码",
      checked: true,
      loginForm: {
        tel: "",
        code: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        file_url: [
          { required: true, message: "请上传简历", trigger: "change" },
        ],
        re_email: [
          { required: true, message: "请输入推荐人邮箱", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      isLogin: null,
      qrCode: "",
      qrState: "",
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
    };
  },
  watch: {
    $route: function () {
      this.currentPath = this.$route.name;
    },
  },
  mounted() {
    this.$pubsub.subscribe("loginSuccess", () => {
      this.isLogin = true;
    });
  },
  created() {
    if (this.$cookies.get("token")) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
      let qrCode = this.$route.query.code;
      let qrState = this.$route.query.id;
      this.qrCode = qrCode;
      if (this.qrCode) {
        this.id = qrState;
        if (typeof this.qrCode == "object" && this.qrCode.length > 0) {
          this.qrCode = this.qrCode[this.qrCode.length - 1];
        }
        if (sessionStorage.getItem("loginMethod") == "h5") {
          login({
            wxcodeh5: this.qrCode,
          }).then((res) => {
            // this.$message.success("登录成功");
            this.$cookies.set("token", `Bearer ${res.token}`, 60 * 60 * 24);
          });
        } else {
          login({
            wxcode: this.qrCode,
          }).then((res) => {
            // this.$message.success("登录成功");
            this.$cookies.set("token", `Bearer ${res.token}`, 60 * 60 * 24);
            this.isLogin = true;
          });
        }
      }
    }
  },
  methods: {
    outLogin() {
      this.$confirm("是否确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cookies.remove("token");
        this.isLogin = false;
      });
    },
    handleClose(formName) {
      this.$refs[formName].clearValidate(["tel"]);
      this.resetForm(formName);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loginForm.tel = "";
      this.loginVisible = false;
    },
    handLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (!this.loginForm.tel) {
          this.errorTel = "请输入手机号";
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.loginForm.tel)) {
          this.errorTel = "手机号格式错误";
          return;
        }
        if (valid) {
          login(this.loginForm).then((res) => {
            this.$message.success("登录成功");
            this.$cookies.set("token", `Bearer ${res.token}`, 60 * 60 * 24);
            this.handleClose(formName);
            this.isLogin = true;
          });
        }
      });
    },
    onLogin() {
      this.loginVisible = true;
    },
    wxlogin() {
      sessionStorage.setItem("loginMethod", "pc");
      let appId = "wxb77761cc82a3140e";
      let local = encodeURIComponent(window.location.href); //获取当前url
      window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${local}&response_type=code&scope=snsapi_login&state=${this.id}#wechat_redirect`;
      // window.open(
      //   `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_login&state=${this.id}#wechat_redirect`,
      //   "_blank"
      // );
    },
    goInest() {
      window.open(`https://www.sinmore.com.cn/inest/9`, "_blank");
    },
    h5login() {
      sessionStorage.setItem("loginMethod", "h5");
      let local = encodeURIComponent(window.location.href); //获取当前url
      let appid = "wx95c437c12b79939e";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect#wechat_redirect`;
    },
    // 获取验证码
    getCodeHandle() {
      if (!this.loginForm.tel) {
        this.errorTel = "请输入手机号";
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.loginForm.tel)) {
        this.errorTel = "手机号格式错误";
        return;
      }
      this.errorTel = "";
      let _this = this;
      if (this.get_code_text !== "获取验证码") return false;
      sms({
        tel: this.loginForm.tel,
      }).then((res) => {
        let num = 60;
        this.get_code_text = num + "s后重试";
        this.timer = setInterval(() => {
          num = num - 1;
          if (num <= 0) {
            clearInterval(_this.timer);
            _this.get_code_text = "获取验证码";
          } else {
            _this.get_code_text = num + "s后重试";
          }
        }, 1000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #666666;
  }
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #666666;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #d0322e;
    background-color: #d0322e;
  }
  .login {
    margin-top: 24px;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > p {
      margin-bottom: 10px;
    }
    > img {
      cursor: pointer;
    }
  }
  .el-footer {
    position: relative;
    margin-top: 40px;
    padding: 53px 0 40px 0;
    height: 563px;
    box-sizing: border-box;
    background-image: url(../assets/images/row6-bg.jpg);
    .footer-right {
      dl {
        display: inline-block;
        color: #fff;
        width: 120px;
        text-align: center;
        vertical-align: top;
        dd {
          margin: 0;
          padding: 0;
          color: #fff;
          padding-left: 30px;
          text-align: left;
          margin-bottom: 20px;
        }
        dt {
          margin: 0;
          padding: 0;
          color: #fff;
          margin-bottom: 46px;
          padding-left: 30px;
          text-align: left;
        }
      }
    }
    a {
      color: #fff;
      font-size: 14px;
    }
    .copy {
      font-size: 12px;
      position: absolute;
      left: 0;
      bottom: 0;
      color: #fff;
    }
    .row6-logo {
      position: absolute;
      left: 0;
      top: 0;
      width: 60px;
    }
    .footer-left {
      margin-top: 167px;
      line-height: 2;
      color: #fff;
      font-size: 14px;
    }
    .fr {
      float: right;
    }
    .fl {
      float: left;
    }
  }
  .main {
    padding: 0;
    min-height: 500px;
    // height: 100% !important;
  }

  .el-header {
    position: relative;
    background: #5b5b5b;
    box-sizing: border-box;

    .icon-c {
      position: relative;
      margin-left: 30px;
      display: flex;
      padding-right: 20px;
      border-left: 1px solid #fff;
      & > span:nth-child(1):hover {
        .prop {
          display: block;
        }
      }
      & > span:nth-child(2):hover {
        .prop-1 {
          display: block;
        }
      }
      & > span:nth-child(3):hover {
        .prop-2 {
          display: block;
        }
      }
      .prop {
        display: none;
        position: absolute;
        left: -21px;
        top: 60px;
        z-index: 999;
        height: 60px;
        line-height: 60px;
        width: 170px;
        z-index: 100;
        font-size: 14px;
        text-align: center;
        background-color: #9f1310;

        .triangle {
          position: absolute;
          left: 23px;
          bottom: 58px;
          border: 10px solid transparent;
          border-bottom: 10px solid #9f1310;
        }
      }
      .prop-1 {
        display: none;
        position: absolute;
        left: -70px;
        top: 60px;
        z-index: 999;
        height: 60px;
        line-height: 60px;
        width: 170px;
        z-index: 100;
        font-size: 14px;
        text-align: center;
        background-color: #9f1310;

        .triangle {
          position: absolute;
          left: 74px;
          bottom: 58px;
          border: 10px solid transparent;
          border-bottom: 10px solid #9f1310;
        }
      }
      .prop-2 {
        display: none;
        position: absolute;
        left: -126px;
        top: 60px;
        z-index: 999;
        height: 60px;
        line-height: 28px;
        padding: 0 10px;
        width: 170px;
        z-index: 100;
        font-size: 12px;
        text-align: center;
        background-color: #9f1310;

        .triangle {
          position: absolute;
          left: 130px;
          bottom: 58px;
          border: 10px solid transparent;
          border-bottom: 10px solid #9f1310;
        }
      }
      & > span {
        position: relative;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        color: #fff;
      }
    }

    .top {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .nav {
      position: relative;
      font-size: 0;
      line-height: 0;
      width: 1200px;
      margin-top: 16px;
      height: 60px;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #d0322e;
      .nav-right {
        width: 170px;
        display: flex;
        align-items: center;
        height: 100%;
        .code {
          display: none;
          position: absolute;
          left: -70px;
          top: 60px;
          border: 1 px solid black;
        }
        .icon-1 {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          > img {
            width: 26px;
          }
          &:hover {
            .code {
              display: block;
            }
          }
        }
        .icon {
          width: 26px;
        }
        .divider {
          display: inline-block;
          width: 1px;
          height: 20px;
          margin: 0 12px;
          background-color: #fff;
        }
      }
      .nav-right :before {
        position: absolute;
        left: 0;
        top: 50%;
        height: 18px;
        margin-top: -9px;
        width: 1px;
        background-color: #fff;
        content: "";
      }
    }

    .nav li {
      font-size: 16px;
      position: relative;
    }

    .nav li:before {
      position: absolute;
      right: 0;
      top: 50%;
      height: 18px;
      margin-top: -9px;
      width: 1px;
      background-color: #fff;
      content: "";
    }

    .nav li:last-child:before {
      display: none;
    }

    .nav li {
      position: relative;
      display: inline-block;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }

    .nav li.active,
    .nav li:hover {
      background-color: #9f1310;
    }

    .nav li:hover .drop-down {
      height: auto;
    }

    .nav li a {
      display: block;
      padding: 0 30px;
      color: #fff;
      font-size: 16px;
    }

    .nav .drop-down {
      position: absolute;
      width: 100%;
      top: 60px;
      overflow: hidden;
      height: 0;
      background-color: #9f1310;
    }

    .nav .drop-down a {
      text-align: center;
      height: 45px;
      line-height: 45px;
    }

    .nav .drop-down a:hover {
      background-color: #87100e;
    }
  }
}
</style>
